<template>
  <div class="recomends">
    <div class="wrapper">
      <div class="btns">
        <button @click="setRecommended('films')" :class="{ active: recommendedType === 'films' }">Фильмы</button>
        <button @click="setRecommended('serials')" :class="{ active: recommendedType === 'serials' }">Сериалы</button>
        <button @click="setRecommended('anime')" :class="{ active: recommendedType === 'anime' }">Аниме</button>
        <button @click="setRecommended('anime-serials')" :class="{ active: recommendedType === 'anime-serials' }">Многосерийные Аниме</button>
        <a href="https://1xmanga.ru"><button>Манга</button></a>
      </div>
    </div>
    <div class="slider-wrapper">
      <div v-if="recommendsD.length" :style="`width:${(recommendsD.length + 1)  * 175}px;`" class="slider">
        <nuxt-link
          v-for="item in recommendsD" :key="`recommends-serials-slider-item-${item.kinopoisk_id}`"
          :to="`/film/${postUrl(item.kinopoisk_id, item.title_ru)}`"
          tag="div"
          class="item"
        >
          <div class="poster">
            <img
              :data-src="filmPoster(item)"
              src="/static_files/no-poster.png"
              :alt="item.title_ru"
              class="lazyload"
            >
            <!-- <img
              :data-src="`https://kinopoiskapiunofficial.tech/images/posters/kp/${item.kinopoisk_id}.jpg`"
              src="/static_files/no-poster.png"
              :alt="item.title_ru"
              class="lazyload"
            > -->
          </div>
          <strong>{{ item.title_ru }}</strong>
        </nuxt-link>
        <div @click="getMoreRecommended(++recommendedPage)" class="item">
          <div class="poster">
            <img
              src="/static_files/icons/more.png"
              class="lazyload"
              alt="Показать еще"
              style="object-fit:contain;opacity:0.75;"
            >
          </div>
          <strong v-if="!loading">Показать еще</strong>
          <strong v-else>Идет загрузка...</strong>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  watch: {
    recommendedType() {
      const slider = document.querySelector('.slider-wrapper')
      if (slider) {
        slider.scrollTo(0,0)
      }
    },
  },
  data() {
    return {
      recommendsD: [],
      recommends: null,
      recommendedType: 'films',
      recommendedPage: 1,
      loading: false,
    }
  },
  async fetch() {
    const { data } = await this.$axios.get(`/api/collaps-top?page=0`)
    this.recommends = data
    this.recommendsD = data.films ? data.films : []
  },
  methods: {
    async getMoreRecommended(page) {
      if (this.loading) return
      this.loading = true
      const { data } = await this.$axios.get(`/api/collaps-top?page=${page}&category=${this.recommendedType}`)
      this.loading = false
      if (data.error) {

      } else {
        const items = this.recommendsD.concat(data)
        this.recommendsD = items
      }
    },
    setRecommended(type = 'serials') {
      this.recommendedType = type
      this.recommendsD = this.recommends[type]
    },
  },
}
</script>

<style lang="stylus">
.recomends
  margin: 90px 0px 0px
  height: 340px
  .wrapper
    width: 100%
    overflow-x: auto
    .btns
      padding: 0px 10px
      width: 540px
      button
        margin-right: 7px
  .slider-wrapper
    margin-top: 20px
</style>